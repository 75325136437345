import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className={'Nav'}>
    <div className="Nav">
      <div className="bar">
        <a className="logo" href="/">
          <div className="Logo undefined">
            <div className="weekday">
              <svg width="339" height="59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M197.564 9.392h-13.058l-13.783 15.412V0h-10.64v49.608h10.64V29.38l14.751 20.228h12.816l-17.411-22.637 16.685-17.58zM87.78 8.429c-13.059 0-21.28 8.187-21.28 20.95 0 12.764 8.221 20.952 21.038 20.952 10.398 0 17.652-4.576 19.587-12.523l.242-.722h-10.64l-.242.24c-.967 2.65-3.627 4.094-8.222 4.094-6.287 0-10.398-3.853-10.882-10.114h30.228v-2.408c-.242-12.763-7.739-20.47-19.83-20.47zm-.242 8.187c5.078 0 8.222 2.65 9.189 7.706H77.38c1.21-4.816 4.837-7.706 10.157-7.706zM46.187 35.401L37.482 9.393h-9.915L18.378 35.16 11.123 9.393H0l13.058 40.216h9.673l9.43-26.73 9.19 26.73h9.672L66.016 9.393H55.134L46.187 35.4zm65.774-6.022c0-12.763 8.222-20.95 21.28-20.95 12.333 0 19.587 7.706 19.829 20.469v2.408h-30.227c.484 6.261 4.595 10.114 10.882 10.114 4.594 0 7.254-1.445 8.222-4.093l.241-.241h10.64l-.242.722c-1.934 7.947-9.189 12.523-19.587 12.523-12.816 0-21.038-8.188-21.038-20.952zm30.227-5.057c-.967-5.057-4.111-7.706-9.189-7.706s-8.705 2.89-10.156 7.706h19.345zm74.48-15.893c5.32 0 9.673 2.167 12.574 6.502V0h10.64v49.608h-10.64v-5.78c-2.659 4.335-7.496 6.743-13.299 6.743-11.124 0-18.62-8.428-18.62-20.71 0-12.522 7.98-21.432 19.345-21.432zm12.574 21.432v-.482c0-7.224-4.352-12.522-10.156-12.522-6.287 0-10.64 5.298-10.882 12.763 0 7.225 4.353 12.523 10.399 12.523 6.529 0 10.639-4.816 10.639-12.282zm49.573-15.412c-2.902-4.094-6.771-6.261-12.091-6.261-11.849 0-19.829 8.91-19.829 21.433 0 12.281 7.496 20.71 18.62 20.71 5.803 0 10.64-2.409 13.3-6.743v5.78h10.64V9.391h-10.64v5.057zm0 14.93v.482c0 7.466-4.111 12.282-10.64 12.282-6.045 0-10.398-5.298-10.398-12.523 0-7.465 4.353-12.763 10.64-12.763 6.045 0 10.398 5.298 10.398 12.523zm26.842-19.987l10.398 24.082 10.882-24.082h11.365L313.637 59H302.03l7.98-15.412-15.719-34.196h11.366z"></path>
              </svg>
            </div>
            <div className="W is-undefined">
              <div className="internal">
                <svg id="w" width="179" height="149" xmlns="http://www.w3.org/2000/svg" style={{left: -85 + 'px', top: -42 + 'px'}}>
                  <path d="M170 0h9v23h-17v14h-17v14h-17v14h-17v14H94v14H77v14H60v14H43v14H26v14H0v-9h17v-14h17v-14h17V98h17V84h17V70h17V56h17V42h17V28h17V14h17V0z"></path>
                </svg>
              </div>
            </div>
            <div className="symbol">
              <svg width="45" height="41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.509 15.804h-17.41v15.171H.412v9.633H26.77V25.437h17.41V.873H34.51v14.931z"></path>
              </svg>
            </div>
            <div className="warriors">
              <svg width="305" height="43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M87.196.188c5.32 0 9.189 2.167 12.09 6.261V1.392h10.64v39.975h-10.64v-5.78c-2.66 4.335-7.496 6.744-13.3 6.744-11.123 0-18.62-8.429-18.62-20.71 0-12.523 7.98-21.433 19.83-21.433zm12.09 21.673v-.481c0-7.225-4.352-12.523-10.398-12.523-6.287 0-10.64 5.298-10.64 12.763 0 7.225 4.353 12.523 10.399 12.523 6.529 0 10.64-4.816 10.64-12.282zM37.623 1.391L46.328 27.4l8.948-26.008h10.64L51.165 41.608H41.25l-8.947-26.73-9.673 26.73h-9.672L.14 1.392h11.124l7.255 25.767 9.189-25.767h9.914zm100.354-.24c-4.837 0-8.222 2.408-10.156 6.743V1.392h-10.64v40.216h10.64V21.861c0-6.261 3.385-10.355 8.705-10.355 1.693 0 3.143 0 4.594.24h.726V1.634h-.726c-.967-.482-1.934-.482-3.143-.482zm20.071 6.743c1.934-4.335 5.32-6.743 10.156-6.743 1.21 0 2.176 0 3.385.482h.484v10.114h-.725c-1.45-.24-2.902-.24-4.595-.24-5.32 0-8.705 4.093-8.705 10.354v19.747h-10.64V1.392h10.64v6.502zm30.468-6.502h-10.64v40.216h10.64V1.392zm6.046 20.228c0-12.281 9.19-21.191 21.763-21.191 6.288 0 11.85 2.167 15.72 6.02 3.868 3.853 6.044 9.151 6.044 15.171 0 12.282-8.947 20.951-21.764 20.951-12.816 0-21.763-8.669-21.763-20.95zm10.882-.24c0 7.465 4.352 12.281 10.881 12.281 6.53 0 10.882-4.575 10.882-12.281 0-7.466-4.352-12.282-10.882-12.282-6.77 0-10.88 4.816-10.88 12.282zM264.931 1.15c-4.837 0-8.222 2.408-10.157 6.743V1.392h-10.639v40.216h10.64V21.861c0-6.261 3.385-10.355 8.705-10.355 1.693 0 3.144 0 4.594.24h.726V1.634h-.484c-.967-.482-1.934-.482-3.385-.482zm22.49 14.93l5.802 1.446c7.74 2.167 11.608 6.02 11.608 12.04 0 7.466-6.77 13.004-15.96 13.004-8.705 0-14.993-4.816-16.686-12.522l-.24-.722H282.1v.481c.967 3.853 4.111 4.576 6.53 4.576 1.208 0 5.32-.241 5.32-4.094 0-2.168-1.21-3.372-5.079-4.576l-5.562-1.685c-7.013-2.409-10.398-6.02-10.398-11.56 0-6.983 6.287-12.04 15.234-12.04 8.706 0 14.751 4.575 16.202 12.281l.242.723h-10.156v-.482c-.484-2.649-2.66-4.335-6.046-4.335-3.385 0-5.078 1.204-5.078 3.613 0 1.685.725 2.89 4.111 3.853z" fill="#fff"></path>
              </svg>
            </div>
          </div>
        </a>
      </div>
      <button className="shop">BUY</button>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
